import { CiLinkedin } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandFacebook } from "react-icons/tb";
import { FaWhatsapp } from "react-icons/fa";
import { ADDRESS } from "../constants";

export function SocialIcons() {
  return (
    <div>
      <h3 className="font-semibold text-blue-900 text-center text-xl mb-2 font-glory">Connect with us</h3>
      <div className="flex flex-row justify-center items-center gap-2">
        <a href="https://www.facebook.com/profile.php?id=61565189637638" target="_blank" rel="noopener noreferrer">
          <TbBrandFacebook size={30} className="text-blue-900 hover:text-blue-700" />
        </a>
        <a href="https://www.instagram.com/renthubapp/" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={30} className="text-blue-900 hover:text-blue-700" />
        </a>
        <a href="https://x.com/renthubapp" target="_blank" rel="noopener noreferrer">
          <FaXTwitter size={30} className="text-blue-900 hover:text-blue-700" />
        </a>
        <a href="https://www.linkedin.com/in/rent-hub" target="_blank" rel="noopener noreferrer">
          <CiLinkedin size={30} className="text-blue-900 hover:text-blue-700" />
        </a>
        <a href={`https://wa.me/${ADDRESS.phone1}`} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={30} className="text-blue-900 hover:text-blue-700" />
        </a>
      </div>
    </div>
  );
}
